<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/restricoes-cliente"
  >
    <h4 class="text-center">Nome da restrição</h4>
    <LineDivider></LineDivider>
    <FormField
      label="Nome"
      v-model="form.descricao"
      :validation="$v.form.descricao"
    />
    <LineDivider class="px-2">
      <h4>Clientes</h4>
    </LineDivider>
    <div v-if="payloadIsLoadedClient || mode == 'add'">
      <div class="text-end">
        <small class="mr-3"
          >{{ addedClients.length }} clientes selecionados</small
        >
      </div>
      <SelectListComponent
        v-model="addedClients"
        :addAllItems="clientsToAdd"
        :disableButton="isLoading"
        :disableOptions="disableOptionsCli"
        :searchEndpoint="clientesSearchEndpoint"
        :loadEndpoint="clientesSearchEndpoint"
        :searchTypes="clientesSearchTypes"
        :addAllEndpoint="clientesSearchEndpoint"
        :itemOptions="clientOptions"
        :showSearchList="false"
        :showAddedListSearch="isUpdating"
        @fetching="onFetching"
        @add-all-items="addAllClientes"
      />
    </div>
    <LineDivider class="px-2">
      <h4>Produtos</h4>
    </LineDivider>
    <div v-if="payloadIsLoadedProduct || mode == 'add'">
      <div class="text-end">
        <small class="mr-3"
          >{{ addedProducts.length }} produtos selecionados</small
        >
      </div>
      <SelectListComponent
        v-model="addedProducts"
        :addAllItems="productsToAdd"
        :disableButton="isLoading"
        :disableOptions="disableOptions"
        :searchEndpoint="produtosSearchEndpoint"
        :searchEndpointParams="searchProdutosEndpointParams"
        :loadEndpoint="produtosLoadEndpoint"
        :addAllEndpoint="produtosAddAllEndpoint"
        :searchTypes="produtosSearchTypes"
        :itemOptions="produtosOptions"
        :showSearchList="false"
        :showAddedListSearch="isUpdating"
        @remove-all="removeAllProducts"
        @fetching="onFetching"
        @remove-itens="removeItens"
        @add-all-items="addAllProdutos"
      />
    </div>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import SelectListComponent from '@/components/select-list/SelectListComponent'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as _ from 'lodash'
import {
  RESTRICAO_CLIENTE_LOAD_PRODUCTS,
  RESTRICAO_CLIENTE_LOAD_CLIENTS,
  RESTRICAO_CLIENTE_CREATE_ALL,
  RESTRICAO_CLIENTE_CREATE
} from '@/store/actions/restricaoCliente'
import { PRODUTOS_LOAD_BY_COD_INTERNO } from '@/store/actions/produto'

export default {
  name: 'RestricaoClienteForm',
  components: {
    SidePopup,
    LineDivider,
    FormField,
    FormButton,
    SelectListComponent
  },
  data: () => ({
    mode: 'edit',
    payloadIsLoadedClient: false,
    payloadIsLoadedProduct: false,
    isLoading: true,
    form: {
      produtos: [],
      clientes: [],
      idrestricaocliente: '',
      descricao: ''
    },
    initialized: false,
    addedProducts: [],
    addedClients: [],
    productsToAdd: [],
    clientsToAdd: [],
    originalProducts: [],
    originalClients: [],
    isRemovingAllProducts: false,
    disableOptions: {
      key: 'id_produto_erp',
      text: 'Não é possível adicionar produtos que não possuem código interno.'
    },
    disableOptionsCli: {
      key: 'idcliente',
      text: 'Não é possível adicionar cliente que não possuem idcliente.'
    },
    clientesSearchTypes: [
      { text: 'Nome', value: 'nomeCliente', search: '' },
      { text: 'Email', value: 'email', search: '' },
      { text: 'Cpf', value: 'cpf', search: '' }
    ],
    produtosSearchTypes: [
      {
        text: 'Produto',
        value: 'nomeProduto',
        search: ''
      },
      {
        text: 'Categoria',
        value: 'categoria',
        search: ''
      },
      {
        text: 'Cod. de barras',
        value: 'codigoBarras',
        search: ''
      },
      {
        text: 'Cod. interno',
        value: 'codigoInterno',
        search: ''
      },
      {
        text: 'Cod. agrupador',
        value: 'codigoAgrupador',
        search: ''
      }
    ],
    startPos: 0,
    startPosCli: 0
  }),
  validations: {
    form: {
      descricao: { required }
    }
  },
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/restricoes-cliente')
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.addedProducts.length <= 0) {
        this.$vueOnToast.pop(
          'error',
          'Adicione ao menos um produto na restrição'
        )
        return
      }
      if (this.addedClients.length <= 0) {
        this.$vueOnToast.pop(
          'error',
          'Adicione ao menos um cliente na restrição'
        )
        return
      }
      this.isLoading = true
      const clientes = _.uniqBy(this.addedClients, 'idcliente')
      const updateProd =
        this.addedProducts.filter(p => p.idcadastroextraproduto).length > 0
      let produtos = []
      let p = []
      let isLarge = false
      let prodsRemovidos = []
      if (updateProd) {
        produtos = _.uniqBy(this.addedProducts, 'idcadastroextraproduto')
        prodsRemovidos = this.itemsRemovidos(
          this.originalProducts,
          produtos,
          'idcadastroextraproduto'
        )
        produtos = produtos.map(p => p.idcadastroextraproduto).filter(pv => pv)
        isLarge = produtos && produtos.length > 1000
        p = isLarge
          ? produtos.map(p => p).slice(0, produtos.length / 2)
          : produtos.map(p => p)
      } else {
        produtos = this.originalProducts
        isLarge = produtos && produtos.length > 1000
        p = isLarge
          ? produtos.map(p => p).slice(0, produtos.length / 2)
          : produtos.map(p => p)
      }
      let restricaoCliente = {
        ...this.form,
        nome: this.form.descricao,
        clientes: clientes,
        produtos: p,
        clientesRemovidos: this.itemsRemovidos(
          this.originalClients,
          clientes,
          'idcliente'
        ),
        produtosRemovidos: prodsRemovidos,
        isRemovingAllProducts: this.isRemovingAllProducts
      }
      this.$store
        .dispatch(RESTRICAO_CLIENTE_CREATE_ALL, restricaoCliente)
        .then(result => {
          if (isLarge && result) {
            restricaoCliente = {
              clientes: clientes,
              idrestricaocliente: result.idrestricaocliente,
              produtos: produtos
                .map(p => p)
                .slice(produtos.length / 2, produtos.length)
            }
            this.$store
              .dispatch(RESTRICAO_CLIENTE_CREATE, restricaoCliente)
              .then(() => {
                this.$vueOnToast.pop(
                  'success',
                  'Restrição de cliente criada com sucesso'
                )
                this.onClose(true)
              })
              .catch(err => {
                const erro =
                  err.response.data.Mensagem ||
                  err.response.data ||
                  'Erro ao criar restrição de cliente'
                this.$vueOnToast.pop('error', erro)
                this.isLoading = false
              })
          } else {
            this.$vueOnToast.pop(
              'success',
              'Restrição de cliente criada com sucesso'
            )
            this.onClose(true)
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Erro ao criar restrição de cliente'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    itemsRemovidos(oldArray, newArray, key) {
      const newIds = newArray.map(item => item[key])
      return oldArray.filter(id => !newIds.includes(id))
    },
    getData(idrestricaocliente) {
      this.isLoading = true
      this.$store
        .dispatch(RESTRICAO_CLIENTE_LOAD_PRODUCTS, {
          idrestricaocliente,
          page: 0,
          limit: 999999
        })
        .then(data => {
          this.form.produtos = data.map(p => p.id_produto_erp)
          this.originalProducts = data.map(p => p.idcadastroextraproduto)
          this.addedProducts = data.map(p => {
            return {
              id_produto_erp: p.id_produto_erp,
              idcadastroextraproduto: p.idcadastroextraproduto
            }
          })
          setTimeout(() => {
            this.payloadIsLoadedProduct = true
          }, 200)
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
      this.$store
        .dispatch(RESTRICAO_CLIENTE_LOAD_CLIENTS, {
          idrestricaocliente,
          page: 0,
          search: '',
          limit: 999999
        })
        .then(data => {
          this.form.clientes = data.map(c => c.idcliente)
          this.originalClients = this.form.clientes
          this.addedClients = this.form.clientes.map(item => {
            return {
              idcliente: item
            }
          })
          setTimeout(() => {
            this.payloadIsLoadedClient = true
          }, 200)
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
      this.isLoading = false
    },
    getProductsByIdsErp(all = false) {
      if (!_.get(this.form, 'produtos.length', 0)) return
      const endPos = this.startPos + 20
      const nProducts = this.form.produtos.slice(this.startPos, endPos)
      if (!_.get(nProducts, 'length', 0)) return
      const payload = { ids: nProducts, page: 0 }
      this.isLoading = true
      this.$store
        .dispatch(PRODUTOS_LOAD_BY_COD_INTERNO, payload)
        .then(result => {
          this.isLoading = false
          const length = _.get(result, 'length', 0)
          if (!length) return
          this.startPos += length
          this.updateAddedProducts(result, all)
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    addAllProdutos(newItems, ids) {
      if (ids) {
        let array = _.unionWith(this.form.produtos, ids)
        this.form.produtos = _.uniq(array)
        this.getProductsByIdsErp(true)
      }
    },
    removeItens(itens) {
      const ids = itens.map(i => i.id_produto_erp)
      this.form.produtos = this.form.produtos.filter(p => ids.indexOf(p) < 0)
      this.addedProducts = this.addedProducts.filter(p => ids.indexOf(p) < 0)
    },
    removeAllProducts() {
      this.isRemovingAllProducts = this.form.clientes.length ? true : false
      this.form.produtos = []
      this.addedProducts = []
    },
    addAllClientes(allItems, newItems) {
      let newValues = (allItems || []).concat(newItems)
      let keyid = _.get(this.clientOptions, 'id', null)
      let key = _.get(this.clientOptions, 'key', null)
      newValues = _.uniqBy(newValues, keyid)
      this.addedClients = newValues.filter(o => o[key])
      this.clientsToAdd = this.addedClients
    },
    updateAddedProducts(newProducts, all) {
      this.addedProducts = (this.addedProducts || []).concat(newProducts)
      this.addedProducts = _.uniqBy(this.addedProducts, 'idproduto')
      this.addedProducts = (this.addedProducts || []).map(p => {
        return {
          ...p,
          selected: false,
          bgColor: ''
        }
      })
      if (all) {
        this.productsToAdd = this.addedProducts
      }
    },
    changeLoading(val) {
      this.isLoading = val
    },
    onFetching(val) {
      this.changeLoading(val || false)
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    title() {
      return this.mode == 'edit'
        ? 'Editar restrição de cliente'
        : 'Nova restrição de cliente'
    },
    clientesSearchEndpoint() {
      return `api/v4/client/${this.getFornecedorId}/search/options`
    },
    clientOptions() {
      return { key: 'nome', id: 'idcliente', payloadProp: 'idcliente' }
    },
    produtosSearchEndpoint() {
      return `api/v4/products/${this.getFornecedorId}`
    },
    searchProdutosEndpointParams() {
      return `&tipoProduto=SIMPLES`
    },
    produtosLoadEndpoint() {
      return `api/v4/products/erp/${this.getFornecedorId}`
    },
    produtosAddAllEndpoint() {
      return `api/v4/products/erp-all/${this.getFornecedorId}`
    },
    produtosOptions() {
      return {
        key: 'nome',
        id: 'id_produto_erp',
        payloadProp: 'ids',
        obs: ['id_produto_erp', 'agrupador_embalagem', 'descricao_embalagem']
      }
    },
    isUpdating() {
      return this.form && this.form.idrestricaocliente ? true : false
    }
  },
  async created() {
    let { idrestricaocliente } = this.$route.params
    let { descricao, id_restricao_erp } = this.$route.query
    if (idrestricaocliente !== 'novo') {
      this.mode = 'edit'
      this.form.idrestricaocliente = idrestricaocliente
      this.form.descricao = descricao
      this.form.id_restricao_erp = id_restricao_erp
      this.getData(idrestricaocliente)
    } else {
      this.mode = 'add'
      this.isLoading = false
    }
  }
}
</script>
